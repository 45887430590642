<template>
    <div class="new_form_box">
        <div class="left_box">
            <div class="us_content">
                <div class="content_left">
                    <div class="left_title_box">
                        <div class="left_title">Request a Demo</div>
                        <div class="left_desc">Harness the power of data + AI with Contrimetric</div>
                        <div class="left_tips">We'd love to show you what your data is capable of.</div>
                    </div>

                    <div class="left_form_content">
                        <FormBoxVue :title="'Sales Region'">
                            <template #content>
                                <el-select v-model="formData.supportOrigin" placeholder="" style="width: 100%;">
                                    <el-option v-for="(item, index) in MC" :key="index" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Name'" :isRequired="true">
                            <template #content>
                                <el-input v-model="formData.userName" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Email'" :isRequired="true">
                            <template #content>
                                <el-input v-model="formData.userEmail" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Country/Region'">
                            <template #content>
                                <el-select v-model="formData.country" filterable placeholder="" style="width: 100%;">
                                    <el-option v-for="(item, index) in countriesList" :key="index" :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Phone number'">
                            <template #content>
                                <el-input v-model="formData.userPhone" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Message'">
                            <template #content>
                                <el-input type="textarea" :rows="3" v-model="formData.remark" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>
                    </div>

                    <div class="left_hint">
                        By submitting this form, I agree that Contrimetric may use my name, email address, phone number,
                        and country of
                        residence to provide support.
                        I agree that Contrimetric may process said data using third-party services for this purpose in
                        accordance with the
                        <span class="storng_text" @click="goToPolicy">Contrimetric Privacy policy</span>.
                    </div>
                </div>
            </div>

            <div class="dialog_footer">
                <el-button type="primary" @click="onDialogSubmit">Submit</el-button>
            </div>
        </div>

        <div class="right_img">
            <img src="../../assets/images/request-demo.svg" alt="">
        </div>
    </div>
</template>

<script>
import { MC, countriesList, onsultantObj } from '../widget/data';
import { applyJournal } from '@/api/widget';
import { isValidateEmail } from "@/utils/validate"
import FormBoxVue from '../widget/components/formBox.vue';

export default {
    components: {
        FormBoxVue
    },
    data() {
        return {
            MC,
            countriesList,
            onsultantObj,
            loading: false,
            defaultRegion: "Southeast Asia",
            formData: {
                supportOrigin: "Southeast Asia",
                userName: "",
                userEmail: "",
                country: "",
                userPhone: "",
                remark: ""
            },
            width: "1200px"
        }
    },
    methods: {
        validateForm() {
            if (this.formData.userName == '' || this.formData.userEmail == '') {
                this.$message.warning("Please enter name and email")

                return false
            }
            return true
        },
        clearFormData() {
            for (let key in this.formData) {
                if (key === 'supportOrigin') {
                    this.formData[key] = this.defaultRegion;
                } else {
                    this.formData[key] = "";
                }
            }
        },
        async onDialogSubmit() {
            if (this.validateForm()) {
                const validationResult = isValidateEmail(this.formData.userEmail)

                if (!validationResult) {
                    this.loading = true

                    await applyJournal(this.formData).then(() => {
                        this.$message.success("Submit successfully")
                    }).catch((err) => {
                        this.$message.error(err || 'Unknown error')
                    }).finally(() => {
                        this.loading = false

                        this.clearFormData()
                    })
                } else {
                    this.$message.warning("Please enter the correct email address.")
                }
            }
        },
        goToPolicy() {
            window.open('https://contrimetric.com/privacy', '_blank')
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.width = width < 768 ? '100%' : '1200px';
        },
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang="scss">
.new_form_box {
    width: 1200px;
    display: flex;
    justify-self: center;
    gap: 100px;
    margin-top: 50px;

    .left_box {
        flex: 0 0 50%;
    }

    .right_img {
        flex: 1;
        display: none;
    }
}

.us_content {
    display: flex;
    justify-content: space-around;
    line-height: 1.5;

    .content_left {
        .left_title_box {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .left_title {
                font-weight: bold;
                font-size: 43px;
                color: #000000;
            }

            .left_desc {
                font-size: 29px;
            }

            .left_tips {
                font-size: 16px;
                color: black;
            }
        }

        .left_hint {
            color: #5e5e60;

            .storng_text {
                color: black;
                text-decoration: underline;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.dialog_footer {
    text-align: left;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .new_form_box {
        flex-direction: column;
        padding: 0 20px;

        .right_img{
            display: none;
        }
    }
}
</style>