import customer1 from "@/assets/images/publication/customer2.png"
import customer2 from "@/assets/images/publication/zhende.png"
import customer3 from "@/assets/images/publication/customer1_3.png"
import customer4 from "@/assets/images/publication/customer1_4.png"
import customer5 from "@/assets/images/publication/fortune.png"
import customer6 from "@/assets/images/publication/logo-ec--en.png"
import customer7 from "@/assets/images/publication/customer1_7.png"
import customer8 from "@/assets/images/publication/customer1_8.png"

export const data = [
    {
        image: customer4,
        link: "https://kneopen.com/",
        text: "KnE Open"
    },
    {
        image: customer5,
        link: "https://www.fortunejournals.com/",
        text: "Fortune Journals"
    },
    {
        image: customer6,
        link: "https://repqj.com",
        text: "European Association for the Development of Renewable Energies, Environment and Power Quality"
    },
    {
        image: customer1,
        link: "https://www.zibelinepub.com/",
        text: "Zibeline International Publishing"
    },
    {
        image: customer2,
        link: "https://zdscience.com/",
        text: "Journals - UK Zhende"
    },
    {
        image: customer3,
        link: "https://www.discovmed.com",
        text: "Discovery Medicine"
    },
    {
        image: customer7,
        link: "https://xuebaozk.haut.edu.cn/",
        text: "河南工业大学学报（自然科学版）"
    },
    {
        image: customer8,
        link: "https://revistatyca.org.mx/index.php/tyca",
        text: "Tecnología y ciencias del agua"
    },
]

export const dxData = [
    {
        image: require("@/assets/images/dxlogo/part1.png"),
        link: "https://saudispine.org/",
        text: "Saudi Spine Society"
    },
    {
        image: require("@/assets/images/dxlogo/part2.png"),
        link: "https://en.sbmu.ac.ir/",
        text: "Ophthalmic Research Center, Shahid Beheshti University of Medical Sciences"
    },
    {
        image: require("@/assets/images/dxlogo/part3.png"),
        link: "https://zkmu.edu.kz/en",
        text: "Marat Ospanov West Kazakhstan Medical University"
    },
    {
        image: require("@/assets/images/dxlogo/part4.png"),
        link: "",
        text: "Research & Clinical Center for Infertility, Yazd Reproductive Sciences Institute, Shahid Sadoughi University of Medical Sciences"
    },
    {
        image: require("@/assets/images/dxlogo/part5.png"),
        link: "https://www.rakmediaoffice.ae/en",
        text: "Sheikh Saud bin Saqr Al Qasimi Foundation for Policy Research"
    },
    {
        image: require("@/assets/images/dxlogo/part6.png"),
        link: "https://www.dha.gov.ae/en",
        text: "Dubai Health Authority"
    },
]